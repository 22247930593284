'use client';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useZodForm,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { useSession } from 'next-auth/react';
import { useParams } from 'next/navigation';
import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import { toast } from 'sonner';
import { contactSupportAction } from './contact-support.action';
import type { ContactSupportSchemaType } from './contact-support.schema';
import { ContactSupportSchema } from './contact-support.schema';
import { isActionSuccessful } from '~/lib/actions/actions.utils';

export type ContactSupportDialogProps = PropsWithChildren<{}>;

export const ContactSupportDialog = (props: ContactSupportDialogProps) => {
  const [open, setOpen] = useState(false);
  const session = useSession();
  const params = useParams();
  const schoolId = params.schoolId;
  const email = session.data?.user.email ?? '';

  const form = useZodForm({
    schema: ContactSupportSchema,
    defaultValues: {
      email,
    },
  });

  const onSubmit = async (values: ContactSupportSchemaType) => {
    const result = await contactSupportAction({
      data: values,
      schoolId: String(schoolId),
    });

    if (!isActionSuccessful(result)) {
      toast.error(result?.serverError ?? 'Something went wrong');
      return;
    }

    toast.success('Your message has been sent.');
    form.reset(result.data);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
      <DialogTrigger asChild>
        {props.children ? (
          props.children
        ) : (
          <Button variant="outline">Contact support</Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Contact Support</DialogTitle>
        </DialogHeader>
        <Form
          form={form}
          onSubmit={async (v) => onSubmit(v)}
          className="flex flex-col gap-4"
        >
          {email ? null : (
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <FormField
            control={form.control}
            name="subject"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Subject</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Send</Button>
        </Form>
      </DialogContent>
    </Dialog>
  );
};